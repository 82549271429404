import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const sliderNavAnim = () => {
  const sliderDom = document.querySelectorAll('section:not(.noAnim).slider-navigation');

  if (sliderDom) {
    sliderDom.forEach((el) => {
      const nav = el.querySelector('.slider-content');
      const slider = el.querySelector('.slider-navigation-component-container');

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: 'top bottom',
          toggleActions: 'play none none reset',
          // markers: true,
        },
      });

      tl.from(nav, {
        alpha: 0,
        yPercent: 10,
        duration: 0.7,
        ease: 'power2.inOut',
      }).from(
        slider,
        {
          alpha: 0,
          yPercent: 10,
          duration: 0.7,
          ease: 'power2.inOut',
        },
        0.3
      );
    });
  }
};

export default sliderNavAnim;
