import { gsap } from 'gsap';

// const reduceMotion = document.documentElement.getAttribute('data-motion-reduce') != null ? true : false;

export default function mainNav() {
  const body = document.querySelector('body');
  const overlay = document.getElementById('overlay');
  let overlayClip = overlay.getAttribute('data-clip');
  const mainNavDom = document.getElementById('main-navigation');
  const mainHeaderDom = document.querySelector('.main-header');
  const menuContainer = mainNavDom.querySelector('.menu-container');
  // const mainContent = document.getElementById('main-content');
  const headerLine = document.getElementById('header-line');

  const navButton = document.getElementById('navigation-button-open');

  const openIcon = navButton.querySelector('.nav-icon');
  const lineOne = openIcon.querySelector('.nav-icon div:nth-child(1)');
  const lineTwo = openIcon.querySelector('.nav-icon div:nth-child(2)');

  const closeIcon = navButton.querySelector('.close-icon');
  const lineOneClose = closeIcon.querySelector('div:nth-child(1)');
  const lineTwoClose = closeIcon.querySelector('div:nth-child(2)');

  const navLinks = mainNavDom.querySelectorAll('li .navigation-link');
  const navLinksInner = mainNavDom.querySelectorAll('li .navigation-link>div');
  const navNumbers = mainNavDom.querySelectorAll('li>span');
  const lateralNav = mainNavDom.querySelectorAll('.lateral-navigation-container');

  // const toggleCSSclasses = (el, ...cls) => cls.map((cl) => el.classList.toggle(cl));

  let windowWidth = window.innerWidth / 2;
  let windowHeight = window.innerHeight / 2;
  let animRunning = false;

  gsap.set(navLinksInner, { alpha: 0, yPercent: 180, rotate: 6 });
  gsap.set(navNumbers, { alpha: 0, yPercent: 180, rotate: 6 });
  gsap.set(lateralNav, { alpha: 0, yPercent: 10 });

  // timelines
  const navClip = gsap.timeline({
    paused: true,
    onStart: () => {
      gsap.set(overlay, { display: 'block' });
    },
    onComplete: () => {
      animRunning = false;
      // mainHeaderDom.classList.toggle('open');
    },
    onReverseComplete: () => {
      gsap.set(overlay, { display: 'none' });
    },
  });

  navClip
    .fromTo(
      overlay,
      {
        clipPath: () => overlayClip,
      },
      {
        clipPath: () => `circle(150% at ${windowWidth}px ${windowHeight}px)`,
        duration: 0.8,
        invalidateOnRefresh: true,
        ease: 'power2.inOut',
        repeatRefresh: true,
      }
    )
    .fromTo(
      navLinksInner,
      {
        alpha: 0,
        yPercent: 180,
        rotate: 6,
      },
      {
        alpha: 1,
        yPercent: 0,
        rotate: 0,
        duration: 0.5,
        stagger: 0.05,
        // invalidateOnRefresh: true,
        onStart: () => {
          gsap.set(menuContainer, { display: 'flex' });
        },
        onReverseComplete: () => {
          gsap.set('html', { clearProps: 'overflow' });
          gsap.set(menuContainer, { display: 'none' });
        },
      },
      0.5
    )
    .fromTo(
      navNumbers,
      {
        alpha: 0,
        yPercent: 180,
        rotate: 6,
      },
      {
        alpha: 1,
        yPercent: 0,
        rotate: 0,
        duration: 0.5,
        stagger: 0.05,
        // invalidateOnRefresh: true,
      },
      0.5
    )
    .fromTo(
      lateralNav,
      {
        alpha: 0,
        yPercent: 10,
      },
      {
        alpha: 1,
        yPercent: 0,
        duration: 0.5,
        // invalidateOnRefresh: true,
      },
      '-=0.3'
    );

  const lineOneTimeline = gsap
    .timeline({ paused: true })
    .to(lineOne, { top: '0%', duration: 0.4, ease: 'power2.out' })
    .to(lineOne, { top: '50%', duration: 0.3, ease: 'power2.in' })
    .to(lineOne, { width: '0%', borderRadius: '50%', duration: 0.2 })
    .to(lineOne, {
      rotate: 360,
      height: '100%',
      width: '100%',
      scale: 1.5,
      top: '-12%',
      alpha: 0,
    });

  const lineTwoTimeline = gsap
    .timeline({ paused: true })
    .to(lineTwo, { top: '100%', duration: 0.4, ease: 'power2.out' })
    .to(lineTwo, { top: '50%', duration: 0.3, ease: 'power2.in' })
    .to(lineTwo, { width: '0%', borderRadius: '50%', duration: 0.2 })
    .to(lineTwo, {
      rotate: 360,
      height: '100%',
      width: '100%',
      scale: 1.5,
      top: '-12%',
      alpha: 0,
    });

  const lineOneCloseTl = gsap
    .timeline({ paused: true })
    .to(lineOneClose, { rotate: 0, duration: 0.2, ease: 'linear' })
    .to(lineOneClose, { top: '0%', duration: 0.55, ease: 'power2.out' })
    .to(lineOneClose, { top: '29%', duration: 0.2 });

  const lineTwoCloseTl = gsap
    .timeline({
      paused: true,
      onComplete: () => {
        lineOneTimeline.time(0).pause();
        lineTwoTimeline.time(0).pause();
        gsap.set(closeIcon, { alpha: 0 });
        animRunning = false;
      },
    })
    .to(lineTwoClose, { rotate: 0, duration: 0.2, ease: 'linear' })
    .to(lineTwoClose, { top: '100%', duration: 0.55, ease: 'power2.out' })
    .to(lineTwoClose, { top: '54%', duration: 0.2 });

  // setTimeout(()=>{
  // lineOneCloseTl.play()
  // lineTwoCloseTl.play()
  // }, 5000)

  navButton.addEventListener('click', () => {
    if (animRunning) {
      return;
    }

    animRunning = true;
    body.classList.toggle('menu-opened');
    navButton.classList.toggle('open');
    mainHeaderDom.classList.toggle('opened');
    openIcon.classList.toggle('open');

    if (navButton.classList.contains('open')) {
      gsap.set('html', { overflow: 'hidden' });
      navClip.timeScale(1).delay(0.75).restart(true);
      lineOneCloseTl.time(0).pause();
      lineTwoCloseTl.time(0).pause();
      gsap.to(document.getElementById('main-content'), { alpha: 0, duration: 0.6, delay: 0.1 });
      gsap.to(headerLine, { alpha: 0 });
      // gsap.set(overlay, {clipPath: circleValues});
      lineOneTimeline.play();
      lineTwoTimeline.play();
      navClip.play();

      gsap.to(closeIcon, { alpha: 1, delay: 1.1 });
    } else {
      document.getElementById('main-content');
      navClip.timeScale(1.3).reverse();
      // lineOneTimeline.reverse();
      // lineTwoTimeline.reverse();

      lineOneCloseTl.play();
      lineTwoCloseTl.play();

      gsap.to(document.getElementById('main-content'), { alpha: 1, delay: 0.9, duration: 0.6 });
      gsap.to(headerLine, { alpha: 1, delay: 0.7 });
    }
  });

  // closing on active link clicked
  navLinks.forEach((link) => {
    link.addEventListener('click', () => {
      if (link.classList.contains('active')) {
        if (animRunning) {
          return;
        }

        animRunning = true;
        body.classList.toggle('menu-opened');
        navButton.classList.toggle('open');
        mainHeaderDom.classList.toggle('opened');
        openIcon.classList.toggle('open');

        document.getElementById('main-content');
        navClip.timeScale(1.3).reverse();
        // lineOneTimeline.reverse();
        // lineTwoTimeline.reverse();

        lineOneCloseTl.play();
        lineTwoCloseTl.play();

        gsap.to(document.getElementById('main-content'), { alpha: 1, delay: 0.9, duration: 0.6 });
        gsap.to(headerLine, { alpha: 1, delay: 0.7 });
      }
    });
  });

  window.addEventListener('resize', () => {
    windowWidth = window.innerWidth / 2;
    windowHeight = window.innerHeight / 2;
    overlayClip = overlay.getAttribute('data-clip');
    navClip.invalidate();
  });
}
