/* eslint-disable no-plusplus */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
// import Swup from 'swup';
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import 'focus-visible';
import Highway from '@dogstudio/highway';
import CustomTransition from './animations/transitions/transitions';
import workTransitionV2 from './animations/transitions/workTransitionV2';
import WorkDefaultTransition from './animations/transitions/workDefaultTransition';
import defaultRenderer from './renderers/default';
import workRenderer from './renderers/work-internal';
import navTransition from './animations/transitions/navTransition';
import mainNav from './navigation';
import preloader from './animations/preloader';
import './utils/sticky-header';

// import footerAnim from './animations/presentation/footerAnim';

// eslint-disable-next-line no-unused-vars

gsap.registerPlugin(ScrollToPlugin);
const debuging = false;

Alpine.plugin(focus);
window.Alpine = Alpine;
Alpine.start();

const preloaderTL = preloader(debuging);

window.addEventListener('load', () => {
  if (!debuging) {
    gsap.to('.pre-screen svg', {
      alpha: 0,
    });

    gsap.to('.pre-screen', {
      yPercent: -100,
      ease: 'power2.inOut',
      delay: 0.9,
      duration: 1,
    });

    gsap.from('main', {
      y: 100,
      alpha: 0,
      delay: 1.4,
      onComplete: () => {
        preloaderTL.kill();
        document.documentElement.setAttribute('data-preloaderrun', false);
      },
      onStart: () => {
        window.scrollTo(0, 0);
      },
    });
  }

  mainNav();
  // footerAnim();

  // eslint-disable-next-line no-unused-vars
  const H = new Highway.Core({
    renderers: {
      company: defaultRenderer,
      homepage: defaultRenderer,
      work: defaultRenderer,
      journal: defaultRenderer,
      projectTemplate: workRenderer,
      journalTemplate: defaultRenderer,
      default: defaultRenderer,
    },
    transitions: {
      // homepage: CustomTransition
      default: CustomTransition,
      projectTemplate: WorkDefaultTransition,
      contextual: {
        fromNav: navTransition,
        toWork: workTransitionV2,
      },
    },
  });

  const links = document.querySelectorAll('#main-navigation-container a');
  H.on('NAVIGATE_IN', ({ location, to }) => {
    // Check Active Link

    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      // Clean class

      link.classList.remove('active');
      // Active link
      if (link.pathname.split('/')[1] === location.pathname.split('/')[1]) {
        link.classList.add('active');
      }
    }

    const currentPage = to.view.getAttribute('data-router-view');
    document.querySelector('body').setAttribute('data-currentpage', currentPage);
  });

  // H.on('NAVIGATE_END', ({ to, location }) => {

  // });

  // NAV
  const navLinks = document.querySelectorAll('#main-navigation-container .navigation-link');
  gsap.set('#main-navigation-container .letter-i', { yPercent: 100, alpha: 0 });
  navLinks.forEach((link) => {
    // console.log(link);

    const linksTimeline = gsap.timeline({ paused: true });

    const mot1 = link.querySelectorAll('.mot-1 span');
    const mot2 = link.querySelectorAll('.mot-2 span');

    // mot1 = [...mot1].reverse();
    // mot2 = [...mot2].reverse();

    linksTimeline.to(mot1, {
      yPercent: -120,
      alpha: 0,
      stagger: 0.05,
      duration: 0.5,
      ease: 'power4.inOut',
    });
    linksTimeline.to(
      mot2,
      { yPercent: 0, alpha: 1, stagger: 0.05, duration: 0.5, ease: 'power4.inOut' },
      0
    );

    link.addEventListener('mouseenter', () => {
      linksTimeline.play();
    });

    link.addEventListener('mouseleave', () => {
      linksTimeline.pause();
      linksTimeline.time(0);
    });
  });
});
