/* eslint-disable no-unused-vars */
import { gsap } from 'gsap';

const customCursor = () => {
  // gsap.registerPlugin(ScrollTrigger)

  const cursorElements = document.querySelectorAll('section.custom-cursor');

  cursorElements.forEach((element) => {
    // const cursorType = element.getAttribute('data-cursor');

    element.addEventListener('mouseover', (e) => {
      const cursor = document.createElement('div');

      // console.log(element.querySelector('.custom-pointer:not(.deleting)'))

      if (element.querySelector('.custom-pointer:not(.deleting)') == null) {
        cursor.classList.add('custom-pointer', 'pointer-cursor');

        element.appendChild(cursor);
      }
      gsap.set(cursor, { x: e.screenX, y: e.offsetY });
      gsap.to(cursor, { alpha: 1, scale: 1.1, visibility: 'visible' });

      // cursor.innerHTML = 'DRAG';
    });

    element.addEventListener('mouseleave', () => {
      const cursor = document.querySelector('.custom-pointer');
      cursor.classList.add('deleting');
      gsap.to(cursor, {
        scale: 0.5,
        duration: 0.1,
        onComplete: () => {
          if (document.querySelector('.custom-pointer.deleting')) {
            element.removeChild(cursor);
          }
        },
      });
    });

    element.addEventListener('mousemove', (e) => {
      const cursor = element.querySelector('.custom-pointer');
      gsap.to(cursor, {
        x: e.screenX,
        y: e.offsetY,
      });
    });

    element.addEventListener('mousedown', () => {
      const cursor = element.querySelector('.custom-pointer');
      gsap.to(cursor, {
        scale: 0.9,
      });
    });

    element.addEventListener('mouseup', () => {
      const cursor = element.querySelector('.custom-pointer');
      gsap.to(cursor, {
        scale: 1,
      });
    });
  });
};

export default customCursor;
