import { acceptCookie } from './cookieUtils';
// const cookiesTemplate = document.querySelector('template#cookies-template');

// const getCookiesComponent = () => cookiesTemplate.content.cloneNode(true);
// const pushCookiesComponent = (component) => {
//   document.body.insertBefore(component, cookiesTemplate);
//   return document.querySelector('#cookies');
// };

export default class Banner {
  constructor(element) {
    this.template = element;
    this.banner = this.template.content.cloneNode(true);
    this.query = `#${this.banner.firstElementChild.id}`;
  }

  init() {
    if (!document.body.contains(document.querySelector(this.query))) {
      document.body.insertBefore(this.banner, this.template);
      this.banner = document.querySelector(this.query);
      this.banner.removeAttribute('aria-hidden');
      this.closeButton = this.banner.querySelector('#accept-cookies');
      this.closeEvent();
    }
  }

  closeEvent() {
    this.closeButton.addEventListener('click', () => {
      this.banner.classList.add('hide');
      this.banner.addEventListener('animationend', () => {
        this.banner.setAttribute('aria-hidden', 'true');
        this.setCookie();
      });
    });
  }

  setCookie() {
    acceptCookie('cookies_accepted');
    this.remove();
  }

  remove() {
    if (document.body.contains(this.banner)) {
      document.body.removeChild(this.banner);
    }
  }
}
