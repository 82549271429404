/* eslint-disable no-plusplus */
import Highway from '@dogstudio/highway';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import postSlider from '../posts-slider';
import projectsSlider from '../projects-slider';
import sliderNav from '../slider-navigation';
import counterSlider from '../counter-slider';
import testimonialSlider from '../testimonials-slider';
import runPresentationAnim from '../animations/presentation';
import cookie from '../cookies/cookie';
// import featuredPostHover from '../journal-featured-post';

class workRenderer extends Highway.Renderer {
  // Built-in methods
  // eslint-disable-next-line class-methods-use-this
  onEnterCompleted() {
    const mainGradient = document.querySelector('main').getAttribute('data-gradient');
    const Alltrigger = ScrollTrigger.getAll();

    for (let i = 0; i < Alltrigger.length; i++) {
      Alltrigger[i].kill();
    }

    postSlider();
    projectsSlider();
    sliderNav();
    counterSlider();
    testimonialSlider();
    runPresentationAnim();
    cookie();
    // featuredPostHover();

    // console.log('hello');

    const main = document.querySelector('main');
    const projectColor = main.getAttribute('data-background');
    // console.log(projectColor);

    if (projectColor !== '' || projectColor !== undefined) {
      document.querySelector('body').style.backgroundColor = projectColor;
    }

    if (mainGradient != null || mainGradient !== '') {
      if (document.getElementById('style-remove')) {
        document.getElementById('style-remove').remove();
      }

      const style = document.createElement('style');
      style.id = 'style-remove';

      style.textContent = `
        a.link-text:after{
          background: ${mainGradient};
        }
      `;

      document.querySelector('body').appendChild(style);
      // gsap.set(headerLine, { backgroundImage: to.getAttribute('data-gradient') });
    }

    ScrollTrigger.refresh();
  }
}

// Don`t forget to export your transition
export default workRenderer;
