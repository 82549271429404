import Splide from '@splidejs/splide';

export default function sliderNav() {
  if (document.querySelector('#slider-navigation-component-container')) {
    const controls = Array.from(document.querySelectorAll('.slider-control-headings li'));
    const getControl = (idx) => controls[idx];

    const navSlider = new Splide('#slider-navigation-component-container', {
      type: 'loop',
      arrows: false,
      autoplay: false,
      cloneStatus: true,
      updateOnMove: true,
      pagination: true,
      mediaQuery: 'min',
      pauseOnHover: false,
      interval: 7000,
      breakpoints: {
        560: {
          // focus: 'center',
          padding: '10%',
          gap: '5%',
          fixedWidth: '560',
        },
        820: {
          padding: '10%',
        },
        1024: {
          // type: 'slide',
          padding: { left: 0, right: '5%' },
          gap: '2%',
          fixedWidth: '100%',
        },
        1200: {
          gap: '100px',
          fixedWidth: '90%',
          padding: { left: 0, right: '10%' },
        },
        1800: {
          fixedWidth: '1600px',
        },
      },
    });

    navSlider.on('move', () => {
      const controller = navSlider.Components.Controller;
      const currentSlide = controller.getIndex();

      const previousControl = controls.filter((control) => control.classList.contains('active'))[0];

      const currentControl = getControl(currentSlide);

      currentControl.classList.add('active');
      if (previousControl !== undefined && currentControl !== previousControl) {
        previousControl.classList.remove('active');
      }
    });

    navSlider.mount();

    const setCurrentSlide = (trigger) => {
      const controller = navSlider.Components.Controller;
      const prevSlide = getControl(controller.getIndex());
      const triggerIndex = +trigger.getAttribute('data-slide-index');

      prevSlide.classList.remove('active');
      navSlider.go(triggerIndex);
    };

    controls.forEach((control) => {
      const controlButton = control.firstElementChild;
      controlButton.addEventListener('click', () => {
        setCurrentSlide(controlButton);
      });
    });
  }
}
