import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

// Founders Grotesk

gsap.registerPlugin(ScrollTrigger, SplitText);

const heroAnim = () => {
  const textComponentDom = document.querySelectorAll('section.hero');

  if (textComponentDom) {
    textComponentDom.forEach((el) => {
      el.querySelectorAll('p').forEach((p) => {
        p.classList.add('gsap-line-padding');
      });

      let delay = 0;

      const p = gsap.utils.toArray(el.querySelectorAll('p'));
      const headers1 = gsap.utils.toArray(el.querySelectorAll('h1'));
      const headers2 = gsap.utils.toArray(el.querySelectorAll('h2'));
      const headers3 = gsap.utils.toArray(el.querySelectorAll('h3'));
      const headers4 = gsap.utils.toArray(el.querySelectorAll('h4'));
      const headers5 = gsap.utils.toArray(el.querySelectorAll('h5'));
      const elArr = headers1.concat(headers2, headers3, headers4, headers5);

      const splitDivs = new SplitText(elArr, { type: 'lines', linesClass: 'pos-line' });
      const splitLines = new SplitText(splitDivs.lines, {
        type: 'lines',
        linesClass: 'anim-line hero',
      });

      const splitP = new SplitText(p, { type: 'lines', linesClass: 'pos-line' });
      const splitLinesP = new SplitText(splitP.lines, {
        type: 'lines',
        linesClass: 'anim-line hero',
      });

      const button = el.querySelector('.button-component');

      // const val = window.matchMedia('(min-width:768px)').matches ? -15 : -10;
      delay = document.documentElement.getAttribute('data-preloaderrun') === 'true' ? 1 : 0;

      gsap.to(splitLines.lines, {
        y: 0,
        duration: 1.5,
        ease: 'power4',
        stagger: 0.2,
        delay,
        onStart: () => {
          gsap.set(elArr, { alpha: 1 });
        },
        scrollTrigger: {
          trigger: el,
          start: 'top 100%',
          // markers: true,
        },
      });

      gsap.to(splitLinesP.lines, {
        y: 0,
        duration: 1.5,
        ease: 'power4',
        stagger: 0.2,
        delay: delay + 0.8,
        onStart: () => {
          gsap.set(p, { alpha: 1 });
        },
        scrollTrigger: {
          trigger: el,
          start: 'top 100%',
          // markers: true,
        },
      });

      gsap.to(button, { y: 0, alpha: 1, delay: delay !== 0 ? delay + 1.1 : 1.1 });
    });
  }
};

export default heroAnim;
