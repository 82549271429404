import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const projectsSliderAnim = () => {
  const sliderDom = document.querySelectorAll('section:not(.noAnim) .projects-slider');

  if (sliderDom) {
    sliderDom.forEach((el) => {
      gsap.from(el, {
        alpha: 0,
        yPercent: 10,
        duration: 0.7,
        ease: 'power2.inOut',
        scrollTrigger: {
          trigger: el,
          start: '-10% bottom',
          toggleActions: 'play reset play reset',
          // markers: true,
        },
      });
    });
  }
};

export default projectsSliderAnim;
