import { gsap } from 'gsap';

const preloader = (debug) => {
  if (debug) {
    gsap.set('.pre-screen', {
      display: 'none',
    });

    return {};
  }

  const preloaderTl = gsap.timeline({ repeat: -1 });
  document.documentElement.setAttribute('data-preloaderrun', true);

  preloaderTl
    .to(
      '.pre-screen .letter',
      {
        autoAlpha: 1,
        stagger: 0.1,
        duration: 0.5,
      },
      0.3
    )
    .to('.pre-screen .letter', {
      alpha: 0,
      stagger: 0.1,
      duration: 0.5,
    });

  preloaderTl.play();

  return preloaderTl;
};

export default preloader;
