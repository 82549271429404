/* eslint-disable no-unused-vars */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

const textComponentAnim = () => {
  const textComponentDom = document.querySelectorAll(
    'section:not(.click-to-action):not(.two-column-container):not(.hero) .text-component.js-animation'
  );

  if (textComponentDom) {
    textComponentDom.forEach((el) => {
      // console.log('text comp');

      // el.querySelectorAll('p').forEach((p) => {
      //   p.classList.add('gsap-line-padding');
      // });

      gsap.set(el.parentElement.parentElement, { alpha: 1 });

      const p = gsap.utils.toArray(el.querySelectorAll('p'));
      const headers1 = gsap.utils.toArray(el.querySelectorAll('h1'));
      const headers2 = gsap.utils.toArray(el.querySelectorAll('h2'));
      const headers3 = gsap.utils.toArray(el.querySelectorAll('h3'));
      const headers4 = gsap.utils.toArray(el.querySelectorAll('h4'));
      const headers5 = gsap.utils.toArray(el.querySelectorAll('h5'));

      const elArr = p.concat(headers1, headers2, headers3, headers4, headers5);

      const splitDivs = new SplitText(elArr, { type: 'lines', linesClass: 'pos-line' });
      const splitLines = new SplitText(splitDivs.lines, { type: 'lines', linesClass: 'anim-line' });

      // const val = window.matchMedia('(min-width:768px)').matches ? -15 : -10;

      splitDivs.lines.forEach((div) => {
        const animLine = div.querySelector('.anim-line');

        gsap.to(animLine, {
          y: 0,
          duration: 1.5,
          ease: 'power4',
          scrollTrigger: {
            trigger: div,
            start: 'top 100%',
            toggleActions: 'play none none reset',
            // markers: true,
          },
          onStart: () => {
            gsap.set(elArr, { alpha: 1 });
          },
        });
      });
    });
  }
};

export default textComponentAnim;
