/* eslint-disable no-unused-vars */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

const quoteAnim = () => {
  const textComponentDom = document.querySelectorAll('.testimonial-block');

  if (textComponentDom) {
    textComponentDom.forEach((el) => {
      const quote = el.querySelector('blockquote');
      const client = el.querySelector('.testimonial-client');
      // console.log(el.parentElement.parentElement);

      const splitDivs = new SplitText(quote, { type: 'lines', linesClass: 'pos-line' });
      const splitLines = new SplitText(splitDivs.lines, { type: 'lines', linesClass: 'anim-line' });

      splitDivs.lines.forEach((div) => {
        const animLine = div.querySelector('.anim-line');

        gsap.to(animLine, {
          y: 0,
          // alpha: 0,
          // rotate: 1,
          duration: 1.5,
          ease: 'power4',
          scrollTrigger: {
            trigger: div,
            start: 'top 100%',
            toggleActions: 'play none none reset',
            // markers: true,
          },
        });
      });

      gsap.from(client, {
        alpha: 0,
        y: 40,
        scrollTrigger: {
          trigger: el,
          start: 'top 100%',
          toggleActions: 'play none none reset',
          // markers: true,
        },
      });
    });
  }
};

export default quoteAnim;
