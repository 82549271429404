import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

export default function projectsSlider() {
  if (document.querySelector('#splide-projects-slider')) {
    const slider = new Splide('#splide-projects-slider', {
      type: 'loop',
      arrows: false,
      autoplay: false,
      pauseOnHover: false,
      focus: 'center',
      cloneStatus: true,
      updateOnMove: true,
      pagination: true,
      mediaQuery: 'min',
      drag: 'free',
      autoScroll: {
        speed: 1,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      breakpoints: {
        768: {
          padding: '10%',
          gap: '5%',
        },
        1280: {
          fixedWidth: '1160px',
        },
        1500: {
          gap: '8%',
        },
      },
    });

    slider.mount({ AutoScroll });
  }
}
