import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const teamAnim = () => {
  const teamDom = document.querySelectorAll('section:not(.noAnim).team');

  if (teamDom) {
    teamDom.forEach((el) => {
      const persons = el.querySelectorAll('.person');

      persons.forEach((person) => {
        const odd = !!person.classList.contains('to-left');

        const screen = person.querySelector('.screen');
        const text = person.querySelector('.person-data');

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: person,
            start: '0% 80%',
            toggleActions: 'play none none none',
            // markers: true,
          },
        });

        tl.to(screen, {
          xPercent: odd ? -110 : 110,
          transformOrigin: odd ? '0% 0%' : '100% 0%',
          rotateX: odd ? -50 : 20,
          rotateY: odd ? 15 : -15,
          duration: 0.9,
          ease: 'power2.inOut',
        }).from(
          text,
          {
            alpha: 0,
            yPercent: 10,
            duration: 1,
            ease: 'power2.out',
          },
          0.2
        );
      });
    });
  }
};

export default teamAnim;
