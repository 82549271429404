import Splide from '@splidejs/splide';

export default function testimonialSlider() {
  const testimonialDom = document.querySelector('.testimonials-content-container.splide');
  if (testimonialDom) {
    const mainGradient = document.querySelector('main').getAttribute('data-gradient');

    const testimonialsSlider = new Splide('.testimonials-content-container.splide', {
      type: 'fade',
      arrows: false,
      autoplay: true,
      cloneStatus: true,
      updateOnMove: true,
      mediaQuery: 'min',
      perPage: 1,
      interval: 8000,
      breakpoints: {
        768: {
          pagination: false,
          arrows: true,
        },
      },
    });

    testimonialsSlider.mount();

    if (mainGradient != null || mainGradient !== '') {
      const style = document.createElement('style');

      style.textContent = `
        .testimonials-content-container .splide__pagination li button.splide__pagination__page{
          background: ${mainGradient} !important;
          opacity: 0.2;
        }

        .testimonials-content-container .splide__pagination li button.splide__pagination__page:hover{
          opacity: 1;
        }

        .testimonials-content-container .splide__pagination li button.splide__pagination__page.is-active{
          background: ${mainGradient} !important;
          opacity: 1;
        }
      `;

      testimonialDom.appendChild(style);
      // gsap.set(headerLine, { backgroundImage: to.getAttribute('data-gradient') });
    }
  }
}
