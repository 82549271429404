import { getCookie } from './cookieUtils';
import Banner from './cookieBannerUtils';

export default function cookie() {
  const cookiesNotAccepted = getCookie('cookies_accepted') === undefined;
  const cookiesTemplate = document.querySelector('#cookies-template');
  const banner = new Banner(cookiesTemplate);

  if (cookiesTemplate) {
    if (cookiesNotAccepted) {
      banner.init();
    } else {
      banner.remove();
    }
  }
}
