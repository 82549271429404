// coverImageContainer
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const oneColumnAnim = () => {
  const containers = document.querySelectorAll('section:not(.noAnim) .one-column');

  if (containers) {
    containers.forEach((el) => {
      const img = el.querySelectorAll('.block-image-ratio-component img');

      if (img) {
        gsap.from(img, {
          scale: 1.1,
          transformOrigin: '50% 50%',
          // alpha: 0.4,
          duration: 10,
          ease: 'linear',
          scrollTrigger: {
            trigger: el,
            start: 'top 100%',
            end: '50% top',
            toggleActions: 'play none none none',
            // scrub: true,
            // markers: true,
          },
        });
      }
    });
  }
};

export default oneColumnAnim;
