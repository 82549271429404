import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const workLandinAnim = () => {
  const workLanding = document.querySelector('section.work-template');

  if (workLanding) {
    const feedCards = workLanding.querySelectorAll('.feed-card');

    feedCards.forEach((card, i) => {
      gsap.to(card, {
        alpha: 1,
        // yPercent: 10,
        duration: 0.8,
        delay: i % 2 === 0 ? 0 : 0.1,
        scrollTrigger: {
          trigger: card,
          start: 'top 100%',
          toggleActions: 'play reset play reset',
          // markers: true,
        },
      });
    });
  }
};

export default workLandinAnim;
