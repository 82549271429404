export default function panels() {
  const panelContainer = document.querySelector('.mobile-featured-navigations');
  const panelButtons = Array.from(panelContainer.querySelectorAll('.buttons-container button'));
  const panelContainers = Array.from(panelContainer.querySelectorAll('.panels .panel'));

  const getActiveButton = () =>
    panelButtons.filter((button) => button.getAttribute('aria-expanded') === 'true')[0];
  const setActiveButton = (prev, next) => {
    prev.setAttribute('aria-expanded', 'false');
    next.setAttribute('aria-expanded', 'true');

    panelContainers.forEach((panel) => {
      if (panel.id === prev.getAttribute('aria-controls')) {
        panel.setAttribute('aria-hidden', 'true');
      } else {
        panel.removeAttribute('aria-hidden');
      }
    });
  };

  panelButtons.forEach((button) => {
    button.addEventListener('click', () => {
      const currentActiveButton = getActiveButton();
      if (button !== currentActiveButton) {
        setActiveButton(currentActiveButton, button);
      }
    });
  });
}
