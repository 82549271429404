import Splide from '@splidejs/splide';

export default function postSlider() {
  if (document.querySelector('#splide-featured-posts.splide')) {
    const slides = document.querySelector('#splide-featured-posts .splide__slide');
    const perPage = 2;

    const slider = new Splide('#splide-featured-posts', {
      type: 'loop',
      arrows: false,
      autoplay: true,
      focus: 'center',
      cloneStatus: true,
      updateOnMove: true,
      pagination: true,
      mediaQuery: 'min',
      breakpoints: {
        768: {
          perPage: 2,
          gap: '2rem',
          padding: '0',
          focus: 1,
          drag: slides.length > perPage,
        },
      },
    });

    slider.mount();
  }
}
