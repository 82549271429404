/* eslint-disable class-methods-use-this */
import Highway from '@dogstudio/highway';
import gsap from 'gsap';

class CustomTransition extends Highway.Transition {
  // Built-in methods
  in({ from, to, done }) {
    const mainNavButton = document.getElementById('navigation-button-open');
    const headerLine = document.getElementById('header-line');
    const body = document.querySelector('body');
    const backgroundColor = getComputedStyle(body).getPropertyValue('--body-background-color');
    const isNavOpen = mainNavButton.classList.contains('open');

    window.scrollTo(0, 0);

    from.remove();

    if (mainNavButton.classList.contains('open')) {
      mainNavButton.click();
    }

    gsap.to(headerLine, {
      delay: 0.5,
      width: '100%',
      duration: 1,
      easy: 'power2.inOut',
      onStart: () => {
        // gsap.set(headerLine, { clearProps: 'background, background-image' });
        gsap.set(headerLine, { clearProps: 'all' });
      },
    });

    gsap.set('#logo-svg-white-text-black', { display: 'none' });
    gsap.set('#logo-svg', { display: 'block' });
    gsap.to('body', { backgroundColor });
    // console.log(to);
    gsap.fromTo(
      to,
      { alpha: 0, y: -80 },
      {
        alpha: 1,
        delay: isNavOpen ? 0.8 : 0.3,
        y: 0,
        duration: 0.5,
        ease: 'power2.in',
        onComplete: done,
      }
    );
  }

  out({ from, done }) {
    const headerLine = document.getElementById('header-line');

    gsap.to(headerLine, {
      width: '0%',
      duration: 0.8,
      easy: 'power2.inOut',
    });

    gsap.fromTo(
      from,
      { alpha: 1, y: 0 },
      { alpha: 0, y: 80, duration: 0.5, ease: 'back.in(1.1)', onComplete: done }
    );
  }
}

// Don`t forget to export your transition
export default CustomTransition;
