/* eslint-disable no-plusplus */
import Highway from '@dogstudio/highway';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import postSlider from '../posts-slider';
import projectsSlider from '../projects-slider';
import sliderNav from '../slider-navigation';
import counterSlider from '../counter-slider';
import testimonialSlider from '../testimonials-slider';
import runPresentationAnim from '../animations/presentation';
import cookie from '../cookies/cookie';
import panelContainers from '../utils/navPanels';
import customCursor from '../utils/cursor';
// import featuredPostHover from '../journal-featured-post';

gsap.registerPlugin(ScrollToPlugin);

class defaultRenderer extends Highway.Renderer {
  // Built-in methods
  // eslint-disable-next-line class-methods-use-this
  onEnterCompleted() {
    const Alltrigger = ScrollTrigger.getAll();
    for (let i = 0; i < Alltrigger.length; i++) {
      Alltrigger[i].kill();
    }

    postSlider();
    projectsSlider();
    sliderNav();
    counterSlider();
    testimonialSlider();
    runPresentationAnim();
    cookie();
    panelContainers();
    customCursor();

    // scroll to
    const sections = document.querySelectorAll('main section');
    const sectionsArr = gsap.utils.toArray(sections);
    if (document.querySelector('.scroll-arrow')) {
      document.querySelector('.scroll-arrow').addEventListener('click', () => {
        gsap.to(window, { duration: 1, scrollTo: { y: sectionsArr[1], offsetY: 150 } });
      });
    }
    if (document.getElementById('style-remove')) {
      document.getElementById('style-remove').remove();
    }
  }
}

// Don`t forget to export your transition
export default defaultRenderer;
