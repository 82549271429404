// coverImageContainer
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const testimonialsAnim = () => {
  const testimonialsDom = document.querySelectorAll('.testimonials-content-container');

  if (testimonialsDom) {
    testimonialsDom.forEach((el) => {
      gsap.from(el, {
        alpha: 0,
        yPercent: -5,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: el,
          start: 'top 100%',
          end: '50% top',
          toggleActions: 'play none none reset',
          // markers: true,
        },
      });
    });
  }
};

export default testimonialsAnim;
