// journal-featured-posts
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const journalSliderAnim = () => {
  const sliderDom = document.querySelectorAll('section:not(.noAnim) .journal-featured-posts');

  if (sliderDom) {
    sliderDom.forEach((el) => {
      const textEl = el.querySelector('.text-component');
      const sliderEl = el.querySelector('.featured-posts-container');

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: 'top bottom',
          toggleActions: 'play reset play reset',
          // markers: true
        },
      });

      tl.from(textEl, {
        alpha: 0,
        yPercent: 5,
        duration: 0.7,
        ease: 'power2.inOut',
      }).from(
        sliderEl,
        {
          alpha: 0,
          yPercent: 5,
          duration: 0.7,
          ease: 'power2.inOut',
        },
        0.2
      );
    });
  }
};

export default journalSliderAnim;
