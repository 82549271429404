/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-constructor */
class StickyHeader extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.header = document.getElementById('main-header');
    // this.navButton = document.getElementById('navigation-button');
    this.headerBounds = {};
    this.currentScrollTop = 0;
    this.preventReveal = false;
    this.navIsClosed = true;
    // this.onClickNavButtonHandler = this.onNavButtonClick.bind(this);
    // this.navButton.addEventListener('click', this.onClickNavButtonHandler);
    this.currentState = 'static';

    this.onScrollHandler = this.onScroll.bind(this);
    this.hideHeaderOnScrollUp = () => (this.preventReveal = true);

    this.addEventListener('preventHeaderReveal', this.hideHeaderOnScrollUp);
    window.addEventListener('scroll', this.onScrollHandler, false);

    this.createObserver();
  }

  disconnectedCallback() {
    window.removeEventListener('scroll', this.onScrollHandler);
    this.removeEventListener('preventHeaderReveal', this.hideHeaderOnScrollUp);
  }

  createObserver() {
    const observer = new IntersectionObserver((entries, observer) => {
      this.headerBounds = entries[0].intersectionRect;
      observer.disconnect();
    });

    observer.observe(this.header);
  }

  // onNavButtonClick() {
  //   console.log('clicked')
  //   console.log(this.currentState)
  // }

  onScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > this.currentScrollTop && scrollTop > this.headerBounds.bottom) {
      if (this.preventHide) return;
      requestAnimationFrame(this.hide.bind(this));
    } else if (scrollTop < this.currentScrollTop && scrollTop > this.headerBounds.bottom) {
      if (!this.preventReveal) {
        requestAnimationFrame(this.reveal.bind(this));
      } else {
        window.clearTimeout(this.isScrolling);
        this.isScrolling = setTimeout(() => {
          this.preventReveal = false;
        }, 66);
        requestAnimationFrame(this.hide.bind(this));
      }
    } else if (scrollTop <= this.headerBounds.top) {
      requestAnimationFrame(this.reset.bind(this));
    }
    this.currentScrollTop = scrollTop;
  }

  hide() {
    if (!this.header.classList.contains('opened')) {
      this.header.classList.remove('sticky-reset');
      this.header.classList.add('sticky-hidden', 'sticky-animate');
      this.currentState = 'hide';
    }
  }

  reveal() {
    this.header.classList.add('sticky', 'sticky-visible', 'sticky-animate');
    this.header.classList.remove('sticky-hidden');
    this.header.classList.remove('sticky-reset');
    this.currentState = 'reveal';
  }

  reset() {
    this.header.classList.remove('sticky-hidden', 'sticky', 'sticky-visible', 'sticky-animate');
    this.header.classList.add('sticky-reset');
    this.currentState = 'reset';
  }
}

customElements.define('sticky-header', StickyHeader);
