import Splide from '@splidejs/splide';

export default function counterSlider() {
  const counterDom = document.querySelector('.counters-container.splide');

  if (counterDom) {
    const mainGradient = document.querySelector('main').getAttribute('data-gradient');

    const counterBlockSlider = new Splide('.counters-container.splide', {
      type: 'loop',
      arrows: false,
      autoplay: true,
      cloneStatus: true,
      updateOnMove: true,
      pagination: true,
      mediaQuery: 'min',
      perPage: 1,
      interval: 10000,
      breakpoints: {
        768: {
          destroy: true,
        },
      },
    });

    counterBlockSlider.mount();

    if (mainGradient != null || mainGradient !== '') {
      const style = document.createElement('style');

      style.textContent = `
        section.counters-block .counters-container .splide__pagination li button.splide__pagination__page{
          background: ${mainGradient} !important;
          opacity: 0.2;
        }

        section.counters-block .counters-container .splide__pagination li button.splide__pagination__page:hover{
          opacity: 1;
        }

        section.counters-block .counters-container .splide__pagination li button.splide__pagination__page.is-active{
          background: ${mainGradient} !important;
          opacity: 1;
        }
      `;

      counterDom.appendChild(style);
      // gsap.set(headerLine, { backgroundImage: to.getAttribute('data-gradient') });
    }
  }
}
