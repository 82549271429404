/* eslint-disable class-methods-use-this */
import Highway from '@dogstudio/highway';
import gsap from 'gsap';

const mainNavButton = document.getElementById('navigation-button-open');

class navTransition extends Highway.Transition {
  // Built-in methods
  in({ from, to, done }) {
    const headerLine = document.getElementById('header-line');
    const body = document.querySelector('body');
    const backgroundColor = getComputedStyle(body).getPropertyValue('--body-background-color');
    window.scrollTo(0, 0);

    // console.log(trigger)

    from.remove();

    if (mainNavButton.classList.contains('open')) {
      mainNavButton.click();
    }

    gsap.to('body', { backgroundColor });

    gsap.fromTo(
      to,
      { alpha: 0, y: -80 },
      {
        alpha: 1,
        delay: 0.8,
        y: 0,
        duration: 0.5,
        ease: 'power.in',
        onStart: () => {
          gsap.set(headerLine, { clearProps: 'background, background-image' });
        },
        onComplete: done,
      }
    );
  }

  out({ from, done }) {
    gsap.fromTo(
      from,
      { alpha: 1, y: 0 },
      { alpha: 0, y: 80, duration: 0.5, ease: 'back.in(1.1)', onComplete: done }
    );
    // gsap.set(from, { alpha: 0, onComplete: done });
  }
}

// Don`t forget to export your transition
export default navTransition;
