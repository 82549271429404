/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import Highway from '@dogstudio/highway';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class workTransition extends Highway.Transition {
  // Built-in methods
  in({ to, trigger, from, done }) {
    // Reset Scroll
    // window.scrollTo(0, 0);

    // Remove Old View
    // from.remove();
    // console.log('v3')

    const overlay = trigger.querySelector('.color');
    const overlayOffset = overlay.getBoundingClientRect();
    const overlayWidth = overlay.offsetWidth;
    const overlayHeight = overlay.offsetHeight;
    const circle = document.getElementById('work-circle');
    const nav = document.getElementById('main-header');
    const headerLine = document.getElementById('header-line');
    // these are relative to the viewport, i.e. the window

    const inTl = gsap.timeline({
      delay: 0.3,
      paused: true,
      onStart: () => {
        window.scrollTo(0, 0);
      },
    });

    gsap.set('main', {
      clearProps: 'transform',
    });

    // new
    const tl = gsap.timeline({
      onComplete: () => {
        from.remove();
        gsap.set(circle, { clearProps: 'all' });
        ScrollTrigger.refresh();
        inTl.play();
      },
    });

    inTl.fromTo(
      to,
      {
        alpha: 0,
        y: -50,
      },
      {
        alpha: 1,
        y: 0,
        duration: 0.5,
        ease: 'linear',
        onComplete: done,
      }
    );

    gsap.set(overlay, {
      opacity: 1,
      clipPath: 'unset',
      inset: -9999,
      width: overlay.offsetWidth,
      height: overlay.offsetWidth,
      borderRadius: '50%',
      // margin: 'auto'
    });

    gsap.set(trigger, {
      transition: 'none',
      borderRadius: 65,
    });

    gsap.set(trigger.querySelector('.feedcard-overlay'), {
      transition: 'none',
      borderRadius: 65,
    });

    gsap.set(trigger.querySelector('.feed-gsap'), {
      transition: 'none',
      alpha: 1,
    });

    nav.classList.remove('sticky-hidden');

    gsap.set(circle, {
      top: overlayOffset.top + overlayHeight / 2,
      left: overlayOffset.left + overlayWidth / 2,
      height: overlayHeight - 50,
      width: overlayHeight - 50,
      backgroundColor: overlay.style.backgroundColor,
      clipPath: 'circle(50% at center center)',
    });

    gsap
      .timeline()
      .to(headerLine, {
        width: '0%',
        duration: 0.4,
        easy: 'power2.inOut',
        onComplete: () => {
          gsap.set(headerLine, { backgroundImage: to.getAttribute('data-gradient') });
        },
      })
      .to(headerLine, {
        width: '100%',
        duration: 0.5,
        easy: 'power2.inOut',
        onComplete: () => {
          gsap.set('#logo-svg-white-text-black', { display: 'block' });
          gsap.set('#logo-svg', { display: 'none' });
        },
      });

    tl.to(overlay, {
      width: overlayHeight - 50,
      height: overlayHeight - 50,
      //   borderRadius: '50%',
      duration: 0.7,
      ease: 'power4.out',
    }).to(
      circle,
      {
        scale: 10,
        duration: 0.6,
        ease: 'power4.in',
        onComplete: () => {
          const projectColor = to.getAttribute('data-background');
          if (projectColor !== '' || projectColor !== undefined) {
            gsap.set('body', { backgroundColor: projectColor });
          }
        },
      },
      '-=0.4'
    );
  }

  out({ trigger, done }) {
    trigger.id = 'leaveShowing';
    done();
  }
}

// Don`t forget to export your transition
export default workTransition;
