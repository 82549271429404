import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const logoGridAnim = () => {
  const gridDom = document.querySelectorAll('section:not(.noAnim).grid .logos-grid');

  if (gridDom) {
    gridDom.forEach((el) => {
      const container = el.parentElement.parentElement.parentElement;
      const text = container.querySelector('.text-component');
      const logos = container.querySelectorAll('.logo-grid-item');

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          start: 'top bottom',
          toggleActions: 'play none none reset',
          // markers: true,
        },
      });

      tl.from(text, {
        alpha: 0,
        yPercent: 10,
        duration: 0.7,
        ease: 'power2.inOut',
      }).from(
        logos,
        {
          alpha: 0,
          duration: 0.5,
          stagger: 0.1,
          ease: 'power2.inOut',
        },
        0.3
      );
    });
  }
};

export default logoGridAnim;
