// eslint-disable-next-line no-unused-vars
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import textComponentAnim from './presentation/textComponentAnim';
import projectsSliderAnim from './presentation/projectsSliderAnim';
import journalSliderAnim from './presentation/journalSliderAnim';
import sliderNavAnim from './presentation/sliderNavAnim';
import logoGridAnim from './presentation/logoGridAnim';
import teamAnim from './presentation/teamAnim';
import workLandinAnim from './presentation/workLandinAnim';
import heroAnim from './presentation/heroAnim';
import feedAnim from './presentation/feedAnim';
import quoteAnim from './presentation/quoteAnim';
import moreProjects from './presentation/more-projects';
// import footerAnim from './presentation/footerAnim';

// work animations
// import coverImageContainer from './presentation/work/coverImageContainer';
import twoColumnsAnim from './presentation/work/twoColumnsAnim';
import testimonialsAnim from './presentation/work/testimonialsAnim';
import oneColumnAnim from './presentation/work/oneColumnAnim';
import statsAnim from './presentation/work/statsAnim';
// coverImageContainer

export default function runPresentationAnim() {
  // console.log('hello')

  document.fonts.ready.then(() => {
    heroAnim();
    textComponentAnim();
    feedAnim();
    quoteAnim();
  });

  projectsSliderAnim();
  journalSliderAnim();
  sliderNavAnim();
  logoGridAnim();
  teamAnim();
  workLandinAnim();
  moreProjects();

  // coverImageContainer();
  twoColumnsAnim();
  testimonialsAnim();
  oneColumnAnim();
  statsAnim();

  ScrollTrigger.refresh();

  // footerAnim();
}
