// coverImageContainer
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const twoColumnsAnim = () => {
  const containers = document.querySelectorAll('section:not(.noAnim).two-column-container');

  if (containers) {
    containers.forEach((el) => {
      const columns = el.querySelectorAll('.column');

      columns.forEach((column) => {
        const serviceList = column.querySelector('.service-list-component');
        const textComp = column.querySelector('.text-component');
        const imgs = column.querySelectorAll('.block-image-ratio-component img');
        const svgs = column.querySelectorAll('.svg-component img');
        const videos = column.querySelectorAll('.video-component video');

        if (serviceList || textComp) {
          const elToUse = serviceList || textComp;
          gsap.from(elToUse, {
            yPercent: -10,
            alpha: 0,
            duration: 0.8,
            ease: 'power2.out',
            scrollTrigger: {
              trigger: column,
              start: 'top 100%',
              toggleActions: 'play none none reset',
              // markers: true,
            },
          });
        } else if (imgs || svgs || videos) {
          if (imgs) {
            imgs.forEach((img) => {
              gsap.from(img, {
                scale: 0.92,
                transformOrigin: '50% 50%',
                // alpha: 0.4,
                // duration: 10,
                ease: 'linear',
                scrollTrigger: {
                  trigger: column,
                  start: 'top 100%',
                  end: '30% 50%',
                  toggleActions: 'play none none none',
                  scrub: true,
                  // markers: true,
                },
              });
            });
          }

          if (svgs) {
            svgs.forEach((svg) => {
              gsap.from(svg, {
                scale: 0.92,
                transformOrigin: '50% 50%',
                // alpha: 0.4,
                // duration: 10,
                ease: 'linear',
                scrollTrigger: {
                  trigger: column,
                  start: 'top 100%',
                  end: '30% 50%',
                  toggleActions: 'play none none none',
                  scrub: true,
                  // markers: true,
                },
              });
            });
          }

          if (videos) {
            videos.forEach((video) => {
              gsap.fromTo(
                video,
                { scale: 0.92 },
                {
                  scale: 1,
                  transformOrigin: '50% 50%',
                  // alpha: 0.4,
                  // duration: 10,
                  ease: 'linear',
                  scrollTrigger: {
                    trigger: column,
                    start: 'top 100%',
                    end: '30% 50%',
                    toggleActions: 'play none none none',
                    scrub: true,
                    // markers: true,
                  },
                }
              );
            });
          }
        }
      });
    });
  }
};

export default twoColumnsAnim;
