import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Founders Grotesk

gsap.registerPlugin(ScrollTrigger);

const moreProjects = () => {
  const lineDom = document.querySelectorAll('.more-posts-title');

  if (lineDom) {
    lineDom.forEach((el) => {
      const line = el.querySelector('span div');

      gsap.set(line, { transformOrigin: 'left center' });

      gsap.from(line, {
        scaleX: 0,
        duration: 1.5,
        ease: 'power4',
        scrollTrigger: {
          trigger: el,
          start: 'top 100%',
          toggleActions: 'play none none reset',
          // markers: true,
        },
      });
    });
  }
};

export default moreProjects;
