import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

const feedAnim = () => {
  const textComponentDom = document.querySelectorAll('section.feed-template-title');

  if (textComponentDom) {
    textComponentDom.forEach((el) => {
      gsap.set(el, { alpha: 1 });

      const p = gsap.utils.toArray(el.querySelectorAll('p'));
      const headers1 = gsap.utils.toArray(el.querySelectorAll('h1'));
      const headers2 = gsap.utils.toArray(el.querySelectorAll('h2'));
      const headers3 = gsap.utils.toArray(el.querySelectorAll('h3'));
      const headers4 = gsap.utils.toArray(el.querySelectorAll('h4'));
      const headers5 = gsap.utils.toArray(el.querySelectorAll('h5'));

      const elArr = p.concat(headers1, headers2, headers3, headers4, headers5);

      const splitDivs = new SplitText(elArr, { type: 'lines', linesClass: 'pos-line' });
      const splitLines = new SplitText(splitDivs.lines, {
        type: 'lines',
        linesClass: 'anim-line hero',
      });

      gsap.to(splitLines.lines, {
        y: 0,
        alpha: 1,
        // alpha: 0,
        // rotate: 1,
        duration: 1.5,
        ease: 'power4',
        stagger: 0.2,
        scrollTrigger: {
          trigger: el,
          start: 'top 100%',
          // markers: true,
        },
        onStart: () => {
          gsap.set(elArr, { alpha: 1 });
        },
      });
    });
  }
};

export default feedAnim;
