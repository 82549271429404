/* eslint-disable no-useless-escape */
/* eslint-disable func-names */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const statsAnim = () => {
  // let elements = document.querySelectorAll("li")

  const containers = document.querySelectorAll('section:not(.noAnim).counters-block');

  if (containers) {
    gsap.registerEffect({
      name: 'counterAnim',
      defaults: {
        end: 0,
        duration: 3,
        ease: 'power1',
        increment: 1,
        trigger: '',
      },
      effect: (targets, config) => {
        let num = targets[0].innerText.replace(/\,/g, '');
        targets[0].innerText = num;

        let anim = gsap.to(
          targets,
          {
            duration: config.duration,
            innerText: config.end,
            modifiers: {
              innerText: function (innerText) {
                return gsap.utils
                  .snap(config.increment, innerText)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              },
            },
            ease: config.ease,
            scrollTrigger: {
              trigger: config.trigger,
              start: 'top 100%',
              end: 'top top',
              toggleActions: 'play none none reset',
              // scrub: true,
              // markers: true,
            },
          },
          0
        );

        return anim;
      },
    });

    containers.forEach((el) => {
      const counters = el.querySelectorAll('.counter-value.value');

      if (counters) {
        counters.forEach((counter) => {
          gsap.effects.counterAnim(counter, {
            // alpha: 0.4,
            start: counter.getAttribute('data-initial').replaceAll(',', ''),
            end: counter.getAttribute('data-final').replaceAll(',', ''),
            increment: 1,
            duration: 3,
            trigger: el,
          });
        });
      }
    });
  }
};

export default statsAnim;
